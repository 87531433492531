<template>
<div>
    <a-back-top />
    <kefu />
    <div class="head">
        
         <div class="item" style="width:120px">
             <a  class="name" @click="to('/index')" ><b>浩瀚电商</b></a>
         </div>
         <div class="item">
            <a class="sel" @click="to('/pushTask')">发布计划</a>
        </div>
        <div class="item">
             <a class="sel">计划管理</a>
                <div  class="site-nav-hover-menu" >
                    <ul class="site-nav-dropdown-list">
                        <li class="site-nav-row">
                            <a class="site-nav-a"  @click="to('/task')" @mouseenter="subSelIndex=2" @mouseleave="subSelIndex=0">
                                <a-row >
                                    <a-col :span="5">
                                        <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="none" class="site-nav-dropdown-icon"><g clip-path="url(#clip0)"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 15.75A7.875 7.875 0 0022.998 0H19.5v15.75H23z" fill="#FEBA57"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M32.623 25.375A9.625 9.625 0 0122.998 35H19.5V15.75h3.498a9.625 9.625 0 019.625 9.625z" fill="#787EFF"></path><path d="M2 4a4 4 0 014-4h13.5v22.75H2V4z" fill="#1890ff"></path><path d="M2 22.75h17.5V35H6a4 4 0 01-4-4v-8.25z" fill="#FEBA57"></path></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h35v35H0z"></path></clipPath></defs></svg>
                                    </a-col>
                                    <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                        <div ><b style="site-nav-title">计划查询</b></div>
                                        <div style="color:#6e6d7a">发布过的任务都在这里</div>
                                    </a-col>
                                    <a-col :span="5" class="site-nav-go">
                                            <ArrowRightOutlined   class="animate__animated animate__fadeInLeft" v-show="subSelIndex==2"  :class="''"/>
                                    </a-col>
                                </a-row>
                            </a>            
                        </li>
                        <li class="site-nav-row">
                            <a class="site-nav-a" @click="to('/praise')"  @mouseenter="subSelIndex=3" @mouseleave="subSelIndex=0">
                                <a-row >
                                    <a-col :span="5">
                                        <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="none" class="site-nav-dropdown-icon"><path d="M33.241 14h-2.617v5.523a4.114 4.114 0 01-4.102 4.102H7v9.617c0 .967.791 1.758 1.758 1.758H33.24c.967 0 1.758-.791 1.758-1.758V15.758c0-.967-.791-1.758-1.758-1.758z" fill="#787EFF"></path><path d="M26.25 21H1.75C.787 21 0 20.212 0 19.25V1.75C0 .787.787 0 1.75 0h24.5C27.212 0 28 .787 28 1.75v17.5c0 .962-.788 1.75-1.75 1.75z" fill="#1890ff"></path><path d="M17.126 5.53c-1.315-.153-2.172.49-3.15 1.53-1.04-1.04-1.836-1.683-3.151-1.53-1.805.183-3.212 2.263-2.906 4.068.49 2.875 3.028 4.588 6.087 6.118 3.059-1.53 5.598-3.243 6.087-6.118.275-1.805-1.162-3.885-2.967-4.068z" fill="#fff"></path></svg>
                                    </a-col>
                                    <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                        <div ><b style="site-nav-title">好评管理</b></div>
                                        <div style="color:#6e6d7a">可以对已完成订单设置好评</div>
                                    </a-col>
                                    <a-col :span="5" class="site-nav-go">
                                           <ArrowRightOutlined   class=" animate__animated" v-show="subSelIndex==3"  :class="'animate__fadeInLeft'"/>
                                    </a-col>
                                </a-row>
                            </a>            
                        </li>
                        <!-- <li class="site-nav-row">
                            <a class="site-nav-a" @click="to('/order')"  @mouseenter="subSelIndex=4" @mouseleave="subSelIndex=0">
                                <a-row >
                                    <a-col :span="5">
                                        <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 31" fill="none" class="site-nav-dropdown-icon"><path d="M17.432 30.208l11.245-11.245H6.187l11.245 11.245z" fill="#787EFF"></path><circle cx="26.642" cy="8.357" r="8.357" fill="#1890ff"></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M2.448 2.448a8.357 8.357 0 0111.819 0l-.016.016 2.984 2.985L5.388 17.296l-3.029-3.03.045-.044a8.357 8.357 0 01.044-11.774z" fill="#FEBA57"></path></svg>
                                    </a-col>
                                    <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                        <div ><b style="site-nav-title">订单查询</b></div>
                                        <div style="color:#6e6d7a">查询已完成的订单</div>
                                    </a-col>
                                    <a-col :span="5" class="site-nav-go">
                                            <ArrowRightOutlined   class=" animate__animated"  v-show="subSelIndex==4"  :class="'animate__fadeInLeft'"/>
                                    </a-col>
                                </a-row>
                            </a>            
                        </li> -->
                    </ul>
                </div>
         </div>
         <div class="item" >
            <a class="sel" @click="to('/order')">订单查询</a>
        </div>
          
         <div class="item" v-if="member.PayType==3">
            <a class="sel" @click="to('/payOrder')">任务付款</a>
        </div>

         <!-- <div class="item" v-if="member.PayType==3" >
             <a class="sel">任务付款</a>
                <div  class="site-nav-hover-menu"  >
                    <ul class="site-nav-dropdown-list">
                        <li class="site-nav-row">
                            <a class="site-nav-a"  @click="to('/payOrder')" @mouseenter="subSelIndex=2" @mouseleave="subSelIndex=0">
                                <a-row >
                                    <a-col :span="5">
                                        <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 31" fill="none" class="site-nav-dropdown-icon"><path d="M17.432 30.208l11.245-11.245H6.187l11.245 11.245z" fill="#787EFF"></path><circle cx="26.642" cy="8.357" r="8.357" fill="#1890ff"></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M2.448 2.448a8.357 8.357 0 0111.819 0l-.016.016 2.984 2.985L5.388 17.296l-3.029-3.03.045-.044a8.357 8.357 0 01.044-11.774z" fill="#FEBA57"></path></svg>
                                    </a-col>
                                    <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                        <div ><b style="site-nav-title">待处理</b></div>
                                        <div style="color:#6e6d7a">处理等待付款的订单</div>
                                    </a-col>
                                    <a-col :span="5" class="site-nav-go">
                                            <ArrowRightOutlined   class="animate__animated animate__fadeInLeft" v-show="subSelIndex==2"  :class="''"/>
                                    </a-col>
                                </a-row>
                            </a>            
                        </li>
                        <li class="site-nav-row">
                            <a class="site-nav-a" @click="to('/okPay')"  @mouseenter="subSelIndex=3" @mouseleave="subSelIndex=0">
                                <a-row >
                                    <a-col :span="5">
                                        <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="none" class="site-nav-dropdown-icon"><path d="M33.241 14h-2.617v5.523a4.114 4.114 0 01-4.102 4.102H7v9.617c0 .967.791 1.758 1.758 1.758H33.24c.967 0 1.758-.791 1.758-1.758V15.758c0-.967-.791-1.758-1.758-1.758z" fill="#787EFF"></path><path d="M26.25 21H1.75C.787 21 0 20.212 0 19.25V1.75C0 .787.787 0 1.75 0h24.5C27.212 0 28 .787 28 1.75v17.5c0 .962-.788 1.75-1.75 1.75z" fill="#1890ff"></path><path d="M17.126 5.53c-1.315-.153-2.172.49-3.15 1.53-1.04-1.04-1.836-1.683-3.151-1.53-1.805.183-3.212 2.263-2.906 4.068.49 2.875 3.028 4.588 6.087 6.118 3.059-1.53 5.598-3.243 6.087-6.118.275-1.805-1.162-3.885-2.967-4.068z" fill="#fff"></path></svg>
                                    </a-col>
                                    <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                        <div ><b style="site-nav-title">已完成</b></div>
                                        <div style="color:#6e6d7a">查看已完成付款的订单</div>
                                    </a-col>
                                    <a-col :span="5" class="site-nav-go">
                                           <ArrowRightOutlined   class=" animate__animated" v-show="subSelIndex==3"  :class="'animate__fadeInLeft'"/>
                                    </a-col>
                                </a-row>
                            </a>            
                        </li>
                    </ul>
                </div>
         </div> -->


 
         <div class="item">
            <a class="sel" @click="to('/download')">报表下载</a>
        </div>
        
        <div class="item">
             <a class="sel" >财务管理</a>
            <div  class="site-nav-hover-menu"  >
                <ul class="site-nav-dropdown-list">
                    <li class="site-nav-row" >
                        <a class="site-nav-a"  @click="to('/myPurse')"  @mouseenter="subSelIndex=1" @mouseleave="subSelIndex=0">
                            <a-row >
                                <a-col :span="5">
                                    <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 39" fill="none" class="site-nav-dropdown-icon"><path d="M13.848 2.663C5.054 2.663.373 1.045.373 1.045v36.362c7.468 1.312 11.512.625 15.639-.076 2.48-.422 4.991-.849 8.294-.849 8.793 0 13.474 1.618 13.474 1.618V1.738c-7.467-1.312-11.512-.625-15.638.077-2.481.421-4.992.848-8.294.848z" fill="#82D1D1"></path><mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="38" height="39"><path d="M13.848 2.663C5.054 2.663.373 1.045.373 1.045v36.362c7.468 1.312 11.512.625 15.639-.076 2.48-.422 4.991-.849 8.294-.849 8.793 0 13.474 1.618 13.474 1.618V1.738c-7.467-1.312-11.512-.625-15.638.077-2.481.421-4.992.848-8.294.848z" fill="#82D1D1"></path></mask><g mask="url(#a)" fill="#4BB7BF"><path d="M-4.637-.004l19.932-2.427 5.01 41.15L.373 41.144z"></path><path d="M2.292-3.448l11.817-4.536L32.682 40.4l-11.817 4.537z"></path></g><path d="M19.08 8.028v23.04" stroke="#fff" stroke-width="2.2"></path><path d="M13.523 23.467c.709 5.128 11.113 5.121 11.113 0 0-1.985-1.132-4.1-5.634-4.1-4.087 0-5.153-2.14-5.153-3.777 0-4.793 8.983-5.148 10.394-.43" stroke="#fff" stroke-width="2.3"></path></svg>
                                </a-col>
                                <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                    <div ><b style="site-nav-title">我的钱包</b></div>
                                    <div style="color:#6e6d7a">账户资金概览，充值 / 提现</div>
                                </a-col>
                                <a-col :span="5" class="site-nav-go">
                                        <ArrowRightOutlined   class=" animate__animated" v-show="subSelIndex==1"  :class="'animate__fadeInLeft'"/>
                                </a-col>
                            </a-row>
                        </a>            
                    </li>

                    <li class="site-nav-row" >
                        <a class="site-nav-a"  @click="to('/record')"  @mouseenter="subSelIndex=2" @mouseleave="subSelIndex=0">
                            <a-row >
                                <a-col :span="5">
                                    <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="none" class="site-nav-dropdown-icon"><g clip-path="url(#clip0)"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 15.75A7.875 7.875 0 0022.998 0H19.5v15.75H23z" fill="#FEBA57"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M32.623 25.375A9.625 9.625 0 0122.998 35H19.5V15.75h3.498a9.625 9.625 0 019.625 9.625z" fill="#787EFF"></path><path d="M2 4a4 4 0 014-4h13.5v22.75H2V4z" fill="#1890ff"></path><path d="M2 22.75h17.5V35H6a4 4 0 01-4-4v-8.25z" fill="#FEBA57"></path></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h35v35H0z"></path></clipPath></defs></svg>
                                </a-col>
                                <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                    <div ><b style="site-nav-title">资金流水</b></div>
                                    <div style="color:#6e6d7a">资金账户收支明细</div>
                                </a-col>
                                <a-col :span="5" class="site-nav-go">
                                        <ArrowRightOutlined   class=" animate__animated" v-show="subSelIndex==2"  :class="'animate__fadeInLeft'"/>
                                </a-col>
                            </a-row>
                        </a>            
                    </li>

                    <!-- <li v-if="member&&member.CommissionType>0" class="site-nav-row">
                        <a class="site-nav-a" @click="to('/promote')"  @mouseenter="subSelIndex=3" @mouseleave="subSelIndex=0">
                            <a-row >
                                <a-col :span="5">
                                    <svg style="margin-top:22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 31" fill="none" class="site-nav-dropdown-icon"><path d="M17.432 30.208l11.245-11.245H6.187l11.245 11.245z" fill="#787EFF"></path><circle cx="26.642" cy="8.357" r="8.357" fill="#1890ff"></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M2.448 2.448a8.357 8.357 0 0111.819 0l-.016.016 2.984 2.985L5.388 17.296l-3.029-3.03.045-.044a8.357 8.357 0 01.044-11.774z" fill="#FEBA57"></path></svg>
                                </a-col>
                                <a-col :span="14" style="text-align:left;line-height:24px;padding-top:12px;">
                                    <div ><b style="site-nav-title">我的推广</b></div>
                                    <div style="color:#6e6d7a">查看您推广的商家</div>
                                </a-col>
                                <a-col :span="5" class="site-nav-go">
                                        <ArrowRightOutlined   class=" animate__animated"  v-show="subSelIndex==3"  :class="'animate__fadeInLeft'"/>
                                </a-col>
                            </a-row>
                        </a>            
                    </li> -->

                </ul>
            </div>
         </div>
        <!-- <div class="item">
            <a class="sel" @click="to('/announcement')">公告</a>
         </div> -->
         <div class="item" v-if="member.Name&&member.Name.indexOf('商审')>-1">
            <a class="sel" @click="to('/review')">任务审核</a>
        </div>

        <template v-if="isLogin">
            <div  class="itemRight" >
                    <a  @click="logout"   class="sel" >退出登录</a>
                </div>
                <div  class="itemRight" style="width:105px;margin-top:30px;text-align:left;line-height:20px;">
                    <a-dropdown>
                        <a style="float:left"><UserOutlined /> {{member.Phone?(member.Phone.substr(0,3)+"****"+member.Phone.substr(7,4)):""}}</a>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item>
                                    <a @click="revisePassWordAction">修改密码</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a @click="revisePhoneAction">换绑手机</a>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>
                <div  class="itemRight" style="width:30px;line-height:83px;text-align:left">
                        <a  class="sel">
                            <a-badge v-if="noRead" dot>
                                <BellOutlined   />
                            </a-badge>
                            <BellOutlined v-else  />
                        </a>
                        <div  class="site-nav-hover-menu" style="right:20px">
                            <ul class="site-nav-dropdown-list">
                               
                                <li class="site-nav-row" v-for="(v,i) in announcementList" :key="i">
                                    <a class="site-nav-a" @click="showAnnouncement(v)">
                                        <a-row>
                                            <a-col :span="22" style="text-align:left;line-height:29px;padding-top:12px;padding:10px">
                                                <div >
                                                     <b style="site-nav-title">{{v.Title}}</b>
                                                </div>
                                                <div style="color:#6e6d7a; width:310px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{v.Context}}</div>
                                            </a-col>
                                            <a-col :span="2">
                                                <a-badge v-if="v.NoRead" dot>
                                                    
                                                </a-badge> 
                                            </a-col>
                                        </a-row>
                                    </a>            
                                </li>
                                <li style="height:auto;text-align:center;margin-top:10px">
                                    <a class="site-nav-a">
                                        <a-row>
                                            <a-col :span="24" style="text-align:center;line-height:29px;padding-top:5px;padding:5px">
                                               <a @click="readAll" style="color:#1890ff">全部已读</a>
                                            </a-col>
                                        </a-row>
                                    </a>            
                                </li>

                            </ul>
                        </div>
                </div>
                <div class="itemRight"  style="width:570px;line-height:83px;text-align:right;margin-right:12px">
                    <b style="margin-right:20px;letter-spacing:2px">欢迎您，{{ member.Name}}</b>
                    <a-tooltip placement="bottom">
                        <template #title>
                        <span>积分余额</span>
                        </template>
                        <span style=" ;font-size:16px">
                            <pay-circle-outlined /> <b>{{member.Balance.toFixed(2)}}</b>
                        </span>
                    </a-tooltip>
                    <router-link :to="{path:'/myPurse', query:{topUpVisible:true} }" >
                    <a-button type="dashed" style="margin-left:18px" shape="round"  >充值</a-button>
                    
                    </router-link>
                    
                    <a-tooltip placement="top">
                        <template #title>
                            <div>
                                <a-tag style="margin-left:5px"  >佣金表</a-tag>
                            </div>
                            <div style="font-size:14px;width:500px;letter-spacing:2px">
                                <template  v-for="(item,i) in commissions.Commissions" :key="i">
                                    <div style="display: inline-block;padding:5px;width:130px">
                                        ≤{{parseFloat(item.Key)}}:￥{{parseFloat(item.Value)}}
                                    </div>
                                    <br v-if="(i+1)%2==0" />
                                </template>
                            </div>
                            <div>
                                <a-tag style="margin-left:5px" >评价费</a-tag>
                            </div>
                            <div style="margin-top: 5px;">
                                <a-row style="text-align: center;">
                                    <a-col :span="8">
                                        文字￥{{commissions.TxtCost}} 
                                    </a-col>
                                    <a-col :span="8">
                                        图片￥{{commissions.ImgCost}} 
                                    </a-col>
                                    <a-col :span="8">
                                        视频￥{{commissions.VideoCost}} 
                                    </a-col>
                                </a-row>
                            </div>
                            
                        </template>
                        <a-tag color="#1890ff" style="margin-left:20px;cursor: pointer;">费用说明</a-tag>
                    </a-tooltip>
 
                    
                </div>
                
        </template>         
        <template  v-else>
                <div class="itemRight">
                    <a-button @click="to('/register')" class="loginBtn" style="width:70px;margin-top:20px">
                                注册
                    </a-button>
                </div>
                <div  class="itemRight">
                    <a class="sel" @click="to('/login')">登录</a>
                </div>
                <!-- <div class="itemRight"  style="width:400px">
                    <input type="text"  style="margin-top:21px;width:80%;"  placeholder="订单号" class="text-input" autocorrect="off" autocapitalize="off">
                    <SearchOutlined  class="query-icon"/>
                </div> -->
        </template>
        <a-modal v-model:visible="revisePassWordVisible"  title="修改密码">
            <template #footer>
                <a-button @click="revisePassWordVisible=false">取消</a-button>
                <a-button type="primary"   @click="revisePassWordSumbit">确定</a-button>
            </template>
            <a-form ref="revisePassWordForm" :model="revisePassWordModel" :rules="revisePassWordRules" :label-col="{span: 6}" :wrapper-col="{span: 15}" >
                <a-form-item label="原密码" name="oldLoginPwd">
                    <a-input-password placeholder="请输入原登录密码" v-model:value="revisePassWordModel.oldLoginPwd" />
                </a-form-item>
                <a-form-item label="新密码" name="loginPwd">
                    <a-input-password placeholder="请输入新登录密码" v-model:value="revisePassWordModel.loginPwd" />
                </a-form-item>
                <a-form-item label="确认密码" name="comfrimPwd">
                    <a-input-password placeholder="请确认新登录密码" v-model:value="revisePassWordModel.comfrimPwd" />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model:visible="announcementVisible" :title="announcement.Title" >
            <p  v-html="announcement.Context"></p>
            <template #footer>
                <a-button type="primary"   @click="announcementVisible=false">我知道了</a-button>
            </template>
        </a-modal>

        <a-modal v-model:visible="revisePhoneVisible"  title="换绑手机">
            <template #footer>
                <a-button @click="revisePhoneVisible=false">取消</a-button>
                <a-button type="primary"   @click="revisePhoneSumbit">确定</a-button>
            </template>
            <a-form ref="revisePhoneForm" :model="revisePhoneModel" :rules="revisePhoneRules" :label-col="{span: 6}" :wrapper-col="{span: 15}" >
                <a-form-item label="登录密码" name="loginPwd">
                    <a-input-password placeholder="请验证登录密码" v-model:value="revisePhoneModel.loginPwd" />
                </a-form-item>
                <a-form-item label="新手机号" name="phone">
                       <a-input-search
                        v-model:value="revisePhoneModel.phone"
                        placeholder="请输入新手机号"
                        @search="sendSms"
                        >
                        <template #enterButton>
                            <a-button>
                                <MailOutlined />
                                {{secods>0?secods+' s':'发送'}}
                            </a-button>
                        </template>
                        </a-input-search>
                    <!-- <a-input placeholder="" v-model:value="revisePhoneModel.phone" style="widt"/>
                    <a-button>Default Button</a-button> -->
                </a-form-item>
                <a-form-item label="验证码" name="code">
                    <a-input placeholder="请输入验证码" v-model:value="revisePhoneModel.code" />
                </a-form-item>
            </a-form>
        </a-modal>
         
    </div>
</div>
</template>
<script>
import kefu from  './kefu'
import { ArrowRightOutlined,BellOutlined,UserOutlined,MailOutlined,PayCircleOutlined } from '@ant-design/icons-vue';
import { memberGet,memberSetLogin,memberSendSmsSetPhone,memberSetMobile,announcementGet,memberRead,memberReadAll,memberGetCommission} from '@/request.js'
import { message } from 'ant-design-vue';
const phoneReg = /^1[3-9]\d{9}$/
export default {
    components:{ArrowRightOutlined,BellOutlined,UserOutlined,MailOutlined,PayCircleOutlined,kefu },
    data() {
        return {
            noRead:false,
            announcementVisible:false,
            announcement:{
                Title:"",
                Context:"",
            },
            announcementList:[],
            secods:0,
            revisePhoneRules:{
                loginPwd:[{required:true,message:'请验证登录密码'}],
                phone:[{required:true,message:'请填写新手机号'}],
                code:[{required:true,message:'请填写手机验证码'}],
            },
            revisePhoneModel:{
                loginPwd:"",
                phone:"",
                code:"",
            },
            revisePhoneVisible:false,

            revisePassWordRules:{
                oldLoginPwd:[{required:true,message:'请填写原登录密码'}],
                loginPwd:[{required:true,message:'请填写新登录密码'}],
                comfrimPwd:[{required:true,message:'请填写确认新登录密码'}],
            },
            revisePassWordModel:{
                oldLoginPwd:"",
                loginPwd:"",
                comfrimPwd:"",
            },
            revisePassWordVisible:false,
            isLogin:false,
            selIndex:0,
            subSelIndex:0,
            member:{Balance:0},
            commissions:[],
        };
    },
    created(){
        if(localStorage.getItem("token")!=null){
            this.isLogin=true;
        }else{
            this.isLogin=false;
        }
    },
    mounted(){
        if(this.isLogin){
            var commissionsCache=sessionStorage.getItem("commissions");
            if(commissionsCache==null){
            memberGetCommission({},(res)=>{
                this.commissions=res.Result;
                sessionStorage.setItem("commissions",JSON.stringify(res.Result));
            });
            }else{
                this.commissions=JSON.parse(commissionsCache);
                console.log(this.commissions);
            }
            memberGet({},(res)=>{
                this.member=res.Result;
                console.log(this.member.Name);
            })
            announcementGet({
                pageIndex:1,
                pSize:3,
                queryTxt:"",
            },(res)=>{
                if(res.Result){
                    for(var i=0;i<res.Result.length;i++){
                        if(res.Result[i].NoRead){
                            this.noRead=true;
                        }
                    }
                    this.announcementList=res.Result;
                }
            })
        }
        
    },
    methods: {
        readAll(){
            memberReadAll({},(res)=>{
                if(res.IsSuccess){
                    this.noRead=false;
                    for(var i=0;i<this.announcementList.length;i++){
                        this.announcementList[i].NoRead=false;
                    }
                }
            })
        },
        showAnnouncement(v){
            if(v.NoRead){
                memberRead({id:v.Id},(res)=>{
                    if(res.IsSuccess){
                        v.NoRead=false;
                        if(this.announcementList.find(x=>x.NoRead==true)==undefined){
                            this.noRead=false;
                        }
                    }
                });
            }
            this.announcement=v;
            this.announcementVisible=true;
        },
        sendSms(){
            if(this.secods==0){
                if (!phoneReg.test(this.revisePhoneModel.phone)){
                    message.error("请填写正确的手机号");
                }else{
                    memberSendSmsSetPhone({
                        phone:this.revisePhoneModel.phone
                    },(res)=>{
                        if(res.IsSuccess){
                            this.secods=60;
                            let timer=setInterval(() => {
                                if(this.secods==0){
                                    clearInterval(timer);
                                }else{
                                    this.secods-=1;
                                }
                            }, 1000);
                        }else{
                            message.error(res.Message);
                        }
                    })
                }
            }
        },
        revisePhoneSumbit(){
            this.$refs.revisePhoneForm
            .validate()
            .then(() => {
                // console.log(this.revisePhoneModel)
                memberSetMobile(this.revisePhoneModel,(res)=>{
                    if(res.IsSuccess){
                        this.revisePhoneVisible=false;
                        this.member.Phone=this.revisePhoneModel.phone;
                        message.success("修改成功，请您以后使用新手机号登录");
                        let json=sessionStorage.getItem("member");
                        if(json==null||json=="null"){
                            memberGet({},(res)=>{
                                this.member=res.Result;
                                sessionStorage.setItem("member",JSON.stringify(res.Result));
                            })
                        }else{
                            sessionStorage.setItem("member",JSON.stringify(this.member));
                        }
                    }else{
                        message.error(res.Message);
                    }
                })
            })
            .catch(error => {
                 console.log('error', error);
            });
        },
        revisePhoneAction(){
            this.revisePhoneVisible=true;
        },

        revisePassWordSumbit(){
            this.$refs.revisePassWordForm
            .validate()
            .then(() => {
                if(this.revisePassWordModel.loginPwd!=this.revisePassWordModel.comfrimPwd){
                    message.error("两次输入新密码不一致");
                    return;
                }
                if(this.revisePassWordModel.loginPwd==this.revisePassWordModel.oldLoginPwd){
                    message.error("新密码不能与旧密码一致");
                    return;
                }
                memberSetLogin(this.revisePassWordModel,(res)=>{
                    if(res.IsSuccess){
                        this.revisePassWordVisible=false;
                        message.success("修改成功，您的新密码以生效");
                    }else{
                        message.error(res.Message);
                    }
                })
            })
            .catch(error => {
                 console.log('error', error);
            });
        },
        revisePassWordAction(){
            this.revisePassWordVisible=true;
        },
        to(path){
            this.$router.push(path)
        },
        swichItem(i){
            this.selIndex=i;
        },
        logout(){
            localStorage.removeItem("token");
            sessionStorage.removeItem("member");
            this.to("/login")
        },
    },
}
</script>
<style scoped>

.site-nav-go{
    
line-height:72px;
color: #1890ff;
}
.site-nav-a{
    display:    block;
    margin-left: 22px;
    margin-right: 22px;
    border-radius: 12px;
}

.site-nav-a:hover{
     background: #ECF5FF	;
     color: #1890ff;
 }
.site-nav-row{
  height: 72px;
}
.site-nav-dropdown-arrow{
    margin-right: 8px;
    color: #1890ff;
    opacity: 1;
    width: 12px;
    height: 12px;
}
.site-nav-dropdown-icon{
        flex-shrink: 0;
    width: 30px;
    height: 30px;
        color: #9e9ea7;
    vertical-align: middle;
}

.site-nav-dropdown-list{
    width: 400px;
    padding: 16px 0;
    border-radius: 12px;
    background: #fff;
    list-style: none;
    margin-bottom: 0px;
}
.site-nav-hover-menu:hover{
    display: block;
}
.site-nav-hover-menu{
    z-index: 9;
    display: none;
    position: absolute;
    top: 65px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
    box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
    margin: 0;
    background: transparent;
}
.query-icon{
    width: 50px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 23px;
    right: 270px;
    cursor: pointer;
}
.sel:hover{
    color: #1890ff;
}
.gonggao:hover + .site-nav-hover-menu{
    display: block;
}
.gonggao{

}
.sel:hover + .site-nav-hover-menu{
    display: block;
}
.sel{
    align-items: center;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 83px;
    color: #6e6d7a;
    display: block;
}
.name{
    line-height: 80px;
    display: block;
    text-decoration: underline;
    color:#0d0c22;
    text-decoration:none; 
    font-size: 22px;
    letter-spacing:2px;
}
.head{
    padding-left: 30px;
    padding-right: 30px;
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
}
.item{
    float:left;width:92px;text-align: center;
}
.itemRight{
    float:right;width:92px;text-align: center;
}



</style>